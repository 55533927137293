import React from 'react';
import stars from '../stars.svg';

interface QuoteProps {
    name: string;
    countryCode: string;
}

const QuoteName: React.FC<QuoteProps> = ({ name, countryCode }) => {
    return (
        <strong>- {name} <img className="stars" src={stars} alt="rating 5 out of 5"/> <span className={`fi fi-${countryCode}`}></span></strong>
    );
}

export default QuoteName;
