import React, { useEffect, useRef } from 'react';

// Declare SimplybookWidget to let TypeScript know it exists in the global scope
declare var SimplybookWidget: any;

interface BookingWidgetProps {
    onButtonClick: () => void;
}

const BookingWidget: React.FC<BookingWidgetProps> = ({ onButtonClick }) => {
    const widgetContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src = "//widget.simplybook.it/v2/widget/widget.js";
        script.onload = () => {
            new SimplybookWidget({
                "widget_type": "button",
                "url": "https://mediumhelp.simplybook.it",
                "theme": "default",
                "theme_settings": {
                    "timeline_hide_unavailable": "1",
                    "hide_past_days": "0",
                    "timeline_show_end_time": "0",
                    "timeline_modern_display": "as_slots",
                    "sb_base_color": "#1f3f68",
                    "display_item_mode": "block",
                    "booking_nav_bg_color": "#1f3f68",
                    "body_bg_color": "#f2f2f2",
                    "sb_review_image": "",
                    "dark_font_color": "#192331",
                    "light_font_color": "#f5fcff",
                    "btn_color_1": "#e45f59",
                    "sb_company_label_color": "#ffffff",
                    "hide_img_mode": "0",
                    "show_sidebar": "1",
                    "sb_busy": "#c7b3b3",
                    "sb_available": "#d6ebff"
                },
                "timeline": "modern",
                "datepicker": "top_calendar",
                "is_rtl": false,
                "app_config": {
                    "clear_session": 0,
                    "allow_switch_to_ada": 0,
                    "predefined": []
                },
                "button_title": "Book Now",
                "button_background_color": "green",
                "button_text_color": "#ffffff",
                "button_position": "left",
                "button_position_offset": "50%",
                "container_id": "sbw_052853"
            });
        };
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    useEffect(() => {
        if (widgetContainerRef.current) {
            const widgetButton = widgetContainerRef.current.querySelector('.simplybook-widget-button');
            console.log('widgetButton', widgetButton)
            if (widgetButton !== null) {
                (widgetButton as HTMLElement).addEventListener('click', onButtonClick);
            }
        }
    }, [onButtonClick]);

    return (
        <div ref={widgetContainerRef} className="widget book-widget" id="sbw_052853"></div>
    );
}

export default BookingWidget;