import React from 'react';
import logo from '../gareth_david_medium_logo.svg';
import logoMobile from '../gareth_david_medium_logo_mobile.svg';
import title from '../gareth_david_medium_title2.svg';
import titleMobile from '../gareth_david_medium_title3.svg';


const Header = () => {
    return (
        <div className="container text-center">
            <header>
                <div className="header-wrapper ">
                    <img className="img-fluid my-1 img-logo default" src={logo} alt="Logo"/>
                    <img className="img-fluid my-1 img-logo mobile" src={logoMobile} alt="Logo"/>
                    <img className="img-fluid my-1 img-title default" src={title} alt="Gareth David Spirit Medium"/>
                    <img className="img-fluid my-1 img-title mobile" src={titleMobile}
                         alt="Gareth David Spirit Medium"/>
                </div>
            </header>
        </div>
    );
};

export default Header;
