export const quotesData = [
    {
        name: 'Solange S',
        countryCode: 'gb',
        text: `Spookily accurate is how I would describe Gareth’s gift. I’ve been having readings from Gareth for years now and the accuracy and messages that come through are so detailed and specific. Gareth once said “have you been spitting watermelon pips out while laying on the couch?” Erm…yes I had! It was a hot summer and I was about to give birth and the only thing cooling me was watermelon and I did spit the pips out as was too hot to get up and get a plate. He also warned me about a specific person citing the exact name. He was right. The person turned out to be a frenemy. Family has come through too. Brilliant medium who is also an empath.`
    },
    {
        name: 'Mike K',
        countryCode: 'us',
        text: `As an American psychologist, I have doubted mediums and their abilities until I was introduced to Gareth. He immediately confirmed various things and many things I was unaware of. His abilities are truly remarkable! He not only has great medium abilities, but the way he interacts is calming, informative and beneficial for life changes. I would highly recommend Gareth as he will not disappoint.`
    },
    {
        name: 'Linzi H',
        countryCode: 'gb',
        text: `I have had a few reading's with Gareth and he is absolutely brilliant, very professional at what he does. Our first meeting we connected with a loved one of mine and i am forever grateful for that, bought me so much comfort and reassuarence. Our 2nd meeting we connected with my spirit guides to help guide me during a difficult time in my life. Gareth\'s sessions have really eased my grieving pain and has also helped me to understand myself a little better. Can not wait for another session.`
    },
    {
        name: 'Jack H',
        countryCode: 'gb',
        text: `I have no hesitation in recommending Gareth. The half hour session was enlightening and productive, with Gareth visioning and expressing things that have both affected me in the past and what he could see in the present and future. Money well spent and I look forward to returning to Gareth for future guidance.`
    },
    {
        name: 'Paul R',
        countryCode: 'gb',
        text: `I recently had a session with Gareth, and it was a truly an amazing experience. He was able to pick up on my past journey and understand exactly where I am right now, guiding me as I work through my fourth and final hurdle. He worked seamlessly with my spirit guides, showing me how to communicate more effectively with them. His insights were incredibly accurate, and he left me feeling optimistic about how well I'll do once I'm through this. I highly recommend Gareth to anyone seeking clarity and positivity in their life.`
    },
    {
        name: 'Larry G',
        countryCode: 'gb',
        text: `I had a reading from Gareth, and I was amazed at how accurate the information he gave was. I’ve never had a reading done previously and was not expecting much out of it, but I was blown away by the result. Gareth clearly has a gift, and I would highly recommend his services.`
    },
    {
        name: 'Jo W',
        countryCode: 'au',
        text: `I had the privilege of experiencing Gareth's psychic abilities, and I must say, I was blown away! His insights were incredibly accurate, and he delivered them with compassion and clarity. Gareth is a true master of his craft.`
    },
    {
        name: 'Robert P',
        countryCode: 'us',
        text: `Gareth's psychic readings are simply exceptional. He tunes into your energy with precision and offers profound guidance that resonates deeply. I left our session feeling uplifted and empowered. Gareth is a true gem!`
    },
    {
        name: 'Patrick C',
        countryCode: 'es',
        text: `Gareth's mediumship skills are unparalleled. He connected with my loved ones on the other side with such clarity and sensitivity, providing me with messages that brought comfort and healing. I am forever grateful for his gift.`
    },
    {
        name: 'Lisa H',
        countryCode: 'gb',
        text: `Gareth's psychic abilities are nothing short of extraordinary. He tapped into aspects of my life with astonishing accuracy, leaving me in awe of his talent. I wholeheartedly recommend Gareth to anyone seeking clarity and insight.`
    },

    {
        name: 'Emma Y',
        countryCode: 'gb',
        text: `Gareth's readings are not just meetings; they are profound explorations into the spirit world. The depth of connection he establishes provides not just comfort but real, actionable guidance. Each session feels tailored uniquely to your personal journey, reflecting Gareth’s rare ability to tune into individual energy fields. His empathetic approach makes you feel safe and understood, while his insights illuminate paths forward that were previously hidden in shadows. His guidance during times of decision-making has been invaluable to me, offering clarity when needed most.`
    },
    {
        name: 'Olivia T',
        countryCode: 'gb',
        text: `Never have I encountered a medium who operates with such precision and empathy. Gareth's sessions are a beacon of light in difficult times.`
    },
    {
        name: 'Harry D',
        countryCode: 'gb',
        text: `What Gareth offers isn't just a reading; it's a profound journey into your life's metaphysical blueprint. His keen intuition and deep spiritual connections provide insights that resonate on a personal and universal level. Each session with him unveils layers of messages from the spirit world, often relating to past events yet illuminating future possibilities. His compassionate delivery of potentially challenging truths makes difficult revelations easier to process. Clients leave Gareth's presence empowered, carrying tools and knowledge to embrace their life’s potential fully.`
    },
    {
        name: 'Amelia W',
        countryCode: 'gb',
        text: `Incredible! Gareth’s abilities are truly a gift. He provided insights that resonated deeply and helped me see things clearly.`
    },
    {
        name: 'Isaac N',
        countryCode: 'gb',
        text: `Gareth's connection with the spiritual world is astonishing. His guidance is always spot-on, making every session invaluable.`
    },
    {
        name: 'Sophia L',
        countryCode: 'us',
        text: `The accuracy of Gareth's readings is unmatched. Each session is a testament to his exceptional abilities as he helps navigate through life's complexities with grace and accuracy. He has helped me understand my path with such clarity and precision that I can only describe the experience as transformative. Beyond just providing readings, Gareth creates a space where deep emotional healing can occur. His work includes profound spiritual counseling that addresses core issues, promoting significant personal growth and understanding.`
    },
    {
        name: 'Mason E',
        countryCode: 'us',
        text: `Gareth’s insights are transformative. He has a unique ability to connect deeply and provide guidance that really impacts your life.`
    },
    {
        name: 'Ella R',
        countryCode: 'us',
        text: `A truly enlightening experience with Gareth. His mediumship not only connects you with the other side but also helps you understand your own life better.`
    },
    {
        name: 'Lucas T',
        countryCode: 'us',
        text: `A session with Gareth is like a light in the darkness. His guidance is profound and delivered with genuine care.`
    },
    {
        name: 'Charlotte P',
        countryCode: 'au',
        text: `Gareth's psychic skills are absolutely stellar. His insights have been incredibly helpful and are always delivered with compassion.`
    },
    {
        name: 'Ethan J',
        countryCode: 'au',
        text: `Meeting Gareth was a life-changing event. His abilities to perceive the unseeable have given me much-needed peace.`
    },
    {
        name: 'Mia S',
        countryCode: 'au',
        text: `Gareth has an amazing gift. His readings are detailed and his connection with spirits is profound. Highly recommend.`
    },
    {
        name: 'Ava K',
        countryCode: 'ca',
        text: `Gareth's sessions are always enlightening. He provides clear and precise readings that truly help guide you through life's challenges.`
    },
    {
        name: 'William T',
        countryCode: 'ca',
        text: `Gareth’s ability to communicate with spirits is absolutely incredible. His insights are always spot-on and deeply helpful.`
    },
    {
        name: 'Emily F',
        countryCode: 'nz',
        text: `Gareth is a true master of his craft. His guidance through mediumship is profound and incredibly accurate.`
    },
    {
        name: 'James L',
        countryCode: 'ie',
        text: `I was skeptical at first, but Gareth's reading was eye-opening. His precision and empathy are truly remarkable.`
    },
    {
        name: 'Isabella M',
        countryCode: 'de',
        text: `Gareth's gift is genuine and his sessions more than just conversations; they are profound engagements with the spiritual world. Each reading brings not only connections but also real, actionable advice that resonates on a deep, personal level. His insights into both my past and potential futures have been incredibly accurate, providing comfort and guidance during times of uncertainty. The depth and breadth of his skills are truly impressive, spanning across different realms of the spiritual landscape, making each session uniquely enlightening.`
    },
    {
        name: 'Elijah B',
        countryCode: 'fr',
        text: `I'm truly amazed by Gareth's psychic abilities. He provides clarity and insights that are invaluable.`
    },
    {
        name: 'Abigail V',
        countryCode: 'it',
        text: `Every session with Gareth is profound. He taps into the spiritual realm with ease

 and delivers messages that are deeply resonant.`
    },
    {
        name: 'Benjamin O',
        countryCode: 'mx',
        text: `Gareth's abilities are unmatched. His readings are thorough and provide clarity and comfort in times of uncertainty.`
    },
    {
        name: 'Ling G',
        countryCode: 'jp',
        text: `Gareth's talent is a rare find. He delivers messages from the spiritual world with care and precision, guiding you toward a better path.`
    },
    {
        name: 'Noah P',
        countryCode: 'in',
        text: `Gareth's insights are a gateway to understanding your own life better. He connects with spirits in a way that is both profound and enlightening.`
    },
    {
        name: 'Samuel T',
        countryCode: 'za',
        text: `A session with Gareth is always a journey into the spiritual world. His accuracy and detail are incredibly impactful.`
    },
    {
        name: 'Alexander S',
        countryCode: 'ru',
        text: `Gareth is not just a medium; he is a guide to the spiritual world. His abilities are truly a gift to anyone who consults him.`
    }
];
