import React, {useState} from 'react';
import Quote from "../components/Quotes";
import {quotesData} from '../data/QuotesData';
import "/node_modules/flag-icons/css/flag-icons.min.css";

const ReviewsSection = () => {

    const quotesNo = 4;
    const [visibleQuotes, setVisibleQuotes] = useState(quotesNo);
    const loadMoreQuotes = () => {
        setVisibleQuotes(prevVisibleQuotes => prevVisibleQuotes + quotesNo);
    };

    return (
        <div className="full-width-wrapper inverse">
            <section className="container main">
                <h2>Reviews</h2>
                
                <div>
                    {quotesData.slice(0, visibleQuotes).map((quote, index) => (
                        <Quote key={index} name={quote.name} countryCode={quote.countryCode}>
                            {quote.text}
                        </Quote>
                    ))}
                    {visibleQuotes < quotesData.length && (
                        <button className="more-button" onClick={loadMoreQuotes}>More Reviews </button>
                    )}
                </div>
            </section>
        </div>
    );
};

export default ReviewsSection;
