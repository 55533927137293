import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Arrow from './Arrow';

import '../Carousel.css';
import TitleBar from "./TitleBar";

interface CarouselProps {
    videoReviews: any[];
    setIsModalVisible: (visible: boolean) => void;
    setActiveUrl: (url: number) => void;
}

const Carousel: React.FC<CarouselProps> = ({ videoReviews, setIsModalVisible, setActiveUrl }) => {
    const [showArrow, setShowArrow] = useState<boolean>(true);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 700) {
                setShowArrow(false);
            } else {
                setShowArrow(true);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleOnClick = (num: number) => {
        setIsModalVisible(true);
        setActiveUrl(num);
    };

    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        centerMode: true,
        centerPadding: '0',
        nextArrow: showArrow ? <Arrow direction="next" /> : undefined,
        prevArrow: showArrow ? <Arrow direction="prev" /> : undefined,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <h2>International Video Reviews</h2>
            <Slider {...settings}>
                {videoReviews.map((item, index) => (
                    <div className="carousel-slide" key={index}>
                           <span
                               className={`fi fi-${item.countryCode}`}
                           ></span>
                        <TitleBar name={item.name} countryCode={item.countryCode} />
                        <img className="slider-image" src={item.image} alt={item.alt} onClick={() => handleOnClick(index)} />
                    </div>
                ))}
            </Slider>
        </>
    );
}

export default Carousel;
