import React from 'react';
import Footer from "./components/Footer";
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import AboutSection from "./sections/about";
import ReviewsSection from "./sections/reviews";
import Header from "./sections/header";

function App() {

    return (
        <>
            <Header/>
            <div className="page">
                <AboutSection/>
                <ReviewsSection/>
            </div>
            <Footer/>
        </>
    );
}

export default App;
