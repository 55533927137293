import React from 'react';

function Footer() {
  return (
      <footer className="footer text-center text-lg-start ">
        <div className="container p-4">
          <div className="row">
            {/* Company Info */}
            <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
              <h5 className="text-uppercase">Gareth David</h5>
              <p>
                Gareth David is a naturally gifted medium with a profound connection to the spirit world, offering personal insights and guidance through private sessions and intuitive readings.
              </p>
              <p>
                Email: <a href="mailto:info@mediumhelp.com" className="text-reset">info@mediumhelp.com</a>
              </p>
            </div>

            {/* Useful Links */}
            <div className="col-lg-6 col-md-12 mb-4 mb-md-0">
              <h5 className="text-uppercase">Links</h5>
              <ul className="list-unstyled mb-0">
                <li>
                  <a href="#!" className="text-reset">About</a>
                </li>
                <li>
                  <a href="#!" className="text-reset">Contact</a>
                </li>
                <li>
                  <a href="#!" className="text-reset">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="text-center p-3 footer-bottom" style={{ backgroundColor: 'rgba(0, 0, 0, 1)' }}>
          © {new Date().getFullYear()} Gareth David. All rights reserved.
        </div>
      </footer>
  );
}

export default Footer;

