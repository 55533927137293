import React from 'react';

interface ArrowProps {
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
    direction: 'next' | 'prev';
}

const Arrow: React.FC<ArrowProps> = ({ className, style, onClick, direction }) => {
    return (
        <div
            className={`arrow arrow-${direction} ${className}`}
            style={{ ...style }}
            onClick={onClick}
        />
    );
};

export default Arrow;
