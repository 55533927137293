import React, { useEffect, useRef, useState } from 'react';

interface QuoteProps {
    name: string;
    countryCode: string;
}

const TitleBar: React.FC<QuoteProps> = ({ name, countryCode }) => {
    const titleBarRef = useRef<HTMLDivElement>(null);
    const flagRef = useRef<HTMLSpanElement>(null);
    const [flagLeft, setFlagLeft] = useState<number | null>(null);

    const calculateOffset = () => {
        const titleBar = titleBarRef.current;
        const flag = flagRef.current;

        if (titleBar && flag) {
            const titleBarWidth = titleBar.offsetWidth;
            const flagWidth = flag.offsetWidth;
            const offset = (titleBarWidth - flagWidth) / 2;

            setFlagLeft(offset);
        }
    };

    useEffect(() => {
        calculateOffset();

        window.addEventListener('resize', calculateOffset);
        return () => window.removeEventListener('resize', calculateOffset);
    }, [titleBarRef, flagRef]);

    return (
        <div className="title-bar" ref={titleBarRef}>
            <span>{name}</span>
            {/*<span*/}
            {/*    className={`fi fi-${countryCode}`}*/}
            {/*    ref={flagRef}*/}
            {/*    style={{ position: 'absolute', left: flagLeft ?? 'auto' }}*/}
            {/*></span>*/}
        </div>
    );
};

export default TitleBar;
