import Image1 from "../assets/images/slide1.png";
import Image2 from "../assets/images/slide2.png";
import Image3 from "../assets/images/slide3.png";
import Image4 from "../assets/images/slide4.png";
import Image5 from "../assets/images/slide5.png";
import Image6 from "../assets/images/slide6.png";
import Image7 from "../assets/images/slide7.png";
import Image8 from "../assets/images/slide8.png";
import Image9 from "../assets/images/slide9.png";
import Image10 from "../assets/images/slide10.png";


export  const videoReviews = [
    {
        name: 'Adam P',
        url: 'https://www.youtube.com/embed/uZj3gslVZ1M?si=_n_rzVsdFDy4mfh2',
        image: Image10,
        alt: 'Adam P talks about his experience with Gareth David Psychic Medium',
        countryCode: 'au'
    },

    {
        name: 'Neha Reddy',
        url: 'https://www.youtube.com/embed/19NZGLbmqX4?si=tKOSJdpR92iOeAyr',
        image: Image6,
        alt: 'Neha Reddy talks about her experience with Gareth David Psychic Medium',
        countryCode: 'in'
    },
    {
        name: 'Dr Michael Krugler',
        url: 'https://www.youtube.com/embed/3_12UQ-JBtU?si=Xfr0_27-u0Us5l5v',
        image: Image3,
        alt: 'Dr Michael Krugler talks about his experience with Gareth David Psychic Medium',
        countryCode: 'us'
    },
    {
        name: 'Adriana Theron',
        url: 'https://www.youtube.com/embed/YE6wzmNUja4?si=hAzFTEg6nEVeFdiP',
        image: Image9,
        alt: 'Adriana Theron talks about her experience with Gareth David Psychic Medium',
        countryCode: 'za'
    },
    {
        name: 'DJ Symphony',
        url: 'https://www.youtube.com/embed/tGhrW34gB44?si=Lgpxwxfyf4fiU5q5',
        image: Image2,
        alt: 'DJ Symphony talks about his experience with Gareth David Psychic Medium',
        countryCode: 'us'
    },
    {
        name: 'Roy Connor',
        url: 'https://www.youtube.com/embed/YpGeN479wHM?si=wfqFWXNX3G--mSJx',
        image: Image4,
        alt: 'Roy Connor talks about his experience with Gareth David Psychic Medium',
        countryCode: 'gb'
    },
    {
        name: 'Wayne Dreadski',
        url: 'https://www.youtube.com/embed/DXrJjCSpQyw?si=VSZERawzOeFAbCP6',
        image: Image1,
        alt: 'Wayne Dreadski talks about his experience with Gareth David Psychic Medium',
        countryCode: 'us'
    },
    // {
    //     name: 'Maria Bautista',
    //     url: 'https://www.youtube.com/embed/YE6wzmNUja4?si=hAzFTEg6nEVeFdiP',
    //     image: Image8,
    //     alt: 'Maria Bautista talks about his experience with Gareth David Psychic Medium',
    //     countryCode: 'ph'
    // },
    {
        name: 'Gopalaswami A',
        url: 'https://www.youtube.com/embed/EuZ13l3RqYg?si=gQ6Qb_aoMjtso80X',
        image: Image5,
        alt: 'Gopalaswami A talks about his experience with Gareth David Psychic Medium',
        countryCode: 'in'
    },
    {
        name: 'Oluwaseun Adebayo',
        url: 'https://www.youtube.com/embed/qk2OkzqkrJo?si=0uzGt6rRGwDg9oV3',
        image: Image7,
        alt: 'Oluwaseun Adebayo talks about his experience with Gareth David Psychic Medium',
        countryCode: 'za'
    },
]
