import React, { useEffect, useState } from 'react';
import '../Carousel.css';

interface ModalProps {
    url: any;
    onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ url, onClose }) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const calculateDimensions = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            setDimensions({ width, height });
        };

        calculateDimensions();
        window.addEventListener('resize', calculateDimensions);

        return () => {
            window.removeEventListener('resize', calculateDimensions);
        };
    }, []);

    return (
        <div className="modalwrap">
            <div className="modal" style={{ width: dimensions.width, height: dimensions.height }}>
                <button className="close-button" onClick={onClose}>
                    <span>&times;</span>
                </button>
                <div className="modal-content">
                    <iframe
                        width="100%"
                        height="100%"
                        src={url}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen>
                    </iframe>
                </div>
            </div>
        </div>
    );
};

export default Modal;
