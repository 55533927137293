import React, { useState, useRef, useCallback } from 'react';
import BookingWidget from '../components/BookButton';
import Carousel from "../components/Carousel";
import Modal from "../components/Modal";
import { videoReviews } from "../data/videoReviewsData";
import ContactWidget from "../components/ContactButton";

const AboutSection = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activeUrl, setActiveUrl] = useState(0);

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const handleOpen = () => {
        setIsModalVisible(true);
    };

    const bookingButtonRef = useRef<HTMLDivElement>(null);

    const handleLinkClick = () => {
        const widgetButton = bookingButtonRef.current?.querySelector('.simplybook-widget-button');
        console.log('widgetButton', widgetButton);
        if (widgetButton) {
            (widgetButton as HTMLElement).click();
        }
    };

    const handleButtonClick = () => {
        console.log('Booking widget button clicked');
    };

    return (
        <div className="full-width-wrapper first-element">
            <section className="container main">
                <Carousel
                    videoReviews={videoReviews}
                    setIsModalVisible={setIsModalVisible}
                    setActiveUrl={setActiveUrl}
                />
                {isModalVisible &&
                    <Modal url={videoReviews[activeUrl].url} onClose={handleClose} />
                }

                <br />
                <br />
                <h2>Psychic Medium Service: 50 mins</h2>
                <br />
                <ul className='bullets'>
                    <li>I believe in the power of compassion and connection above all else in my work as a medium.</li>
                    <li>Bridging the gap between our world and the spirit realm without tarot or crystal balls.</li>
                    <li>Offering sincerity, empathy, comfort and healing through genuine communication with the spirit world.</li>
                    <li>Prioritising authenticity and transparency, understanding the skepticism around mediums.</li>
                </ul>
                <ul className='bullets emphasis'>
                    <li>I am not a fortune teller. My role is to connect you with those who have passed over.</li>
                    <li>No fishing for information or psychological tricks such as cold reading.</li>
                    <li>No quibble, full refund if nothing comes through.</li>
                    <li>Additional services available.</li>
                </ul>

                <h1>Book a Session</h1>
                &lt;-- Hit the green button on the left
                <BookingWidget onButtonClick={handleButtonClick} />
                <ContactWidget />
            </section>
        </div>
    );
};

export default AboutSection;
