import React, { useEffect, useRef } from 'react';

declare global {
    interface Window {
        SimplybookWidget: any;
    }
}

function ContactWidget() {
    const widgetContainerRef = useRef(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src = "//widget.simplybook.it/v2/widget/widget.js";
        script.onload = () => {
            new window.SimplybookWidget({
                "widget_type": "contact-button",
                "url": "https://mediumhelp.simplybook.it",
                "theme": "default",
                "theme_settings": {
                    "timeline_hide_unavailable": "1",
                    "hide_past_days": "0",
                    "timeline_show_end_time": "0",
                    "timeline_modern_display": "as_slots",
                    "sb_base_color": "#1f3f68",
                    "display_item_mode": "block",
                    "booking_nav_bg_color": "#1f3f68",
                    "body_bg_color": "#f2f2f2",
                    "sb_review_image": "2",
                    "sb_review_image_preview": "/uploads/mediumhelp/image_files/preview/8c945c011499c5814d16894810a547f8.png",
                    "dark_font_color": "#192331",
                    "light_font_color": "#f5fcff",
                    "btn_color_1": "#7b3ce8",
                    "sb_company_label_color": "#ffffff",
                    "hide_img_mode": "0",
                    "show_sidebar": "1",
                    "sb_busy": "#c7b3b3",
                    "sb_available": "#d6ebff"
                },
                "timeline": "modern",
                "datepicker": "top_calendar",
                "is_rtl": false,
                "app_config": {
                    "clear_session": 1,
                    "allow_switch_to_ada": 0,
                    "predefined": []
                },
                "button_title": "Contact",
                "button_background_color": "red",
                "button_text_color": "#ffffff",
                "button_position": "left",
                "button_position_offset": "35%",
                "container_id": "sbw_qz3e9w"
            });
        };
        document.head.appendChild(script);

        return () => {
            if (script.parentNode) {
                script.parentNode.removeChild(script);
            }
        };
    }, []);

    return (
        <div className="widget contact-widget" id="sbw_qz3e9w" ref={widgetContainerRef}></div>
    );
}

export default ContactWidget;
